<script lang="ts">import { onDestroy, onMount } from "svelte";
import { metatags, url } from "@roxi/routify";
import SkillItem from "../components/SkillItem.svelte";
import ButtonWithLine from "../components/ButtonWithLine.svelte";
import { Positioner } from "../scripts/Positioner";
import { MetatagCreator } from "../scripts/MetatagCreator";
import skillsJson from "../data/skills.json";
MetatagCreator.createMeta(metatags, $url());
let buttonTexts = skillsJson.cats;
let skills = skillsJson.skills;
let activeCats = [];
let skillContainers = [];
let buttonCol;
let container;
//TODO maybe implement force directed layout not random
let positioner;
onMount(() => {
    activeCats = [buttonTexts[0]];
    positioner = new Positioner(skillContainers, 640, container, buttonCol);
});
onDestroy(() => positioner.destroy());
</script>

<div class="flex overflow-y-auto flex-col w-full h-full">
    <h3 class="pt-16 pl-16 mb-8 sm:mb-2"><span>Skills</span></h3>
    <div class="flex relative justify-center h-full" bind:this="{container}">
        <div class="hidden absolute w-full h-full sm:block">
            {#each skills as props, i}
                <div class="absolute" bind:this="{skillContainers[i]}">
                    <SkillItem {...props} activeCat="{activeCats}" />
                </div>
            {/each}
        </div>
        <div
            class="hidden flex-col justify-around p-4 my-12 my-auto h-3/4 sm:flex"
            bind:this="{buttonCol}">
            {#each buttonTexts as btn_txt, i}
                <ButtonWithLine
                    click="{() => (activeCats = [buttonTexts[i]])}"
                    activeCats="{activeCats}"
                    color="{i}"
                    text="{btn_txt}" />
            {/each}
        </div>
        <div
            class="block flex flex-wrap justify-center content-start m-4 w-full text-white sm:hidden">
            {#each buttonTexts as category, i}
                <div class="flex-grow p-5 w-max">
                    <h4 class="text-2xl font-l relative w-max cat-header color-{i}">{category}</h4>
                    <ul>
                        {#each skills.filter((skill) => skill.cats.includes(category)) as skill}
                            <li>
                                <SkillItem {...skill} activeCat="{[buttonTexts[i]]}" />
                            </li>
                        {/each}
                    </ul>
                </div>
            {/each}
        </div>
    </div>
</div>

<style type="scss">.cat-header:before {
  content: "";
  transform-origin: 0 95%;
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  border-radius: 0.375rem;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-scale-y: .2;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 150ms;
}

.color-0 :before {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}

.color-1:before {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.color-2:before {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}

.color-3:before {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}

.color-4:before {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}</style>
