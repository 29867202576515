<script>
    import { metatags, url } from "@roxi/routify";
    import { MetatagCreator } from "../scripts/MetatagCreator";
    import Job from "../components/Job.svelte";
    import jobs from "../data/jobs.json";

    MetatagCreator.createMeta(metatags, $url());
</script>

<div class="overflow-hidden w-full h-full">
    <div class="overflow-y-scroll w-full h-full">
        <h3 class="pt-16 pl-16">Work <span>Experience</span></h3>
        <div class="relative pb-2 w-full max-w-3xl job-container lg:max-w-none">
            {#each jobs as job}
                <Job {...job} />
            {/each}
        </div>
    </div>
</div>

<style type="scss">.job-container:before {
  content: "";
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  border-radius: 0.125rem;
  height: 100%;
  margin-left: -0.125rem;
  position: absolute;
  top: 0px;
  left: 3rem;
  width: 0.25rem;
}

.line:after {
  content: "";
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 4px;
  height: 1.25rem;
  margin-left: -0.625rem;
  position: absolute;
  top: 2rem;
  left: 3rem;
  width: 1.25rem;
}

.line .card:before {
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 8px;
  border-left-width: 0px;
  position: absolute;
  top: 2rem;
  right: 100%;
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
  border-right-color: currentColor;
}

@media (min-width: 1280px) {
  .job-container:before {
    content: "";
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
    border-radius: 0.125rem;
    height: 100%;
    margin-left: -0.125rem;
    position: absolute;
    top: 0px;
    left: 50%;
    width: 0.25rem;
  }

  .line:after {
    content: "";
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
    border-radius: 9999px;
    border-width: 4px;
    height: 1.25rem;
    margin-left: -0.625rem;
    position: absolute;
    top: 2rem;
    left: 50%;
    width: 1.25rem;
  }

  .line .card:before {
    content: "";
    border-color: transparent;
    border-style: solid;
    border-width: 8px;
    border-right-width: 0px;
    position: absolute;
    top: 2rem;
    left: 100%;
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
    border-left-color: currentColor;
  }

  .line:nth-child(2n) .card {
    margin-right: 1.25rem;
    margin-left: auto;
  }

  .line:nth-child(2n) .card:before {
    border-left-width: 0px;
    border-right-width: 8px;
    left: auto;
    right: 100%;
    border-right-color: currentColor;
  }
}</style>
