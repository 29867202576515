<script>
    // import Calendar from "../../assets/icons/lnr-calendar-full.svg";
    // import Code from "../../assets/icons/lnr-code.svg";

    export let title;
    export let position;
    export let timeframe;
    export let description;
    export let tags;
</script>

<!--Code class="inline-block mr-1 mb-1 w-4 h-4 text-white fill-current"/-->
<!--Calendar class="inline-block mr-1 mb-1 w-3 h-3 text-white fill-current"/-->

<!--Code class="inline-block mr-1 mb-1 w-4 h-4 text-white fill-current"/-->
<!--Calendar class="inline-block mr-1 mb-1 w-3 h-3 text-white fill-current"/-->

<div class="relative my-4 w-full line">
    <div class="relative p-6 mr-5 ml-24 text-white bg-gray-700 xl:ml-5 xl:w-5/12 xl:mr-auto card">
        <h4 class="mb-2 text-xl">{title}</h4>
        <div class="shadow-inner mb-4 bg-gray-800 text-xs flex rounded-sm spacing">
            <span class="py-0.5 px-2.5 inline-block rounded-sm w-1/2">{position}</span>
            <span class="py-0.5 px-2.5 inline-block rounded-sm w-1/2">{timeframe}</span>
        </div>
        <p class="mb-4 font-light text-white">{description}</p>
        <ul class="flex flex-wrap justify-center text-xs">
            {#each tags as tag}
                <li
                    class="bg-gray-600 font-light rounded-sm inline-block text-center flex-grow tag-spacing">
                    {tag}
                </li>
            {/each}
        </ul>
    </div>
</div>

<style type="scss">.line:after {
  content: "";
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 4px;
  height: 1.25rem;
  margin-left: -0.625rem;
  position: absolute;
  top: 2rem;
  left: 3rem;
  width: 1.25rem;
}

.card:before {
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 8px;
  border-left-width: 0px;
  position: absolute;
  top: 2rem;
  right: 100%;
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
  border-right-color: currentColor;
}

@media (min-width: 1280px) {
  .line:after {
    margin-left: -0.625rem;
    left: 50%;
  }

  .line:nth-child(2n) .card {
    margin-right: 1.25rem;
    margin-left: auto;
  }

  .line:nth-child(2n) .card:before {
    border-left-width: 0px;
    border-right-width: 8px;
    left: auto;
    right: 100%;
    border-right-color: currentColor;
  }

  .card:before {
    border-width: 8px;
    border-right-width: 0px;
    left: 100%;
    border-left-color: currentColor;
  }
}

.spacing {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.tag-spacing {
  margin: 0.125rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}</style>
