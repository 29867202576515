<!--TODO make cool console output for fellow devs.-->
<script lang="ts">import Sidebar from "../components/Sidebar.svelte";
import Navbar from "../components/Navbar.svelte";
import LRBar from "../components/LRBar.svelte";
import Home from "../../assets/icons/lnr-home.svg";
import User from "../../assets/icons/lnr-user.svg";
import Briefcase from "../../assets/icons/lnr-briefcase.svg";
import Envelope from "../../assets/icons/lnr-envelope.svg";
import Code from "../../assets/icons/lnr-code.svg";
import GraduationHat from "../../assets/icons/lnr-graduation-hat.svg";
import { onMount } from "svelte";
const pageIcons = [
    [Home, "Home", "/index"],
    [User, "About Me", "/about"],
    [Briefcase, "Experience", "/experience"],
    [GraduationHat, "Education", "/education"],
    [Code, "Skills", "/skills"],
    [Envelope, "Contact", "/contact"],
];
let bg;
let active = false;
//TODO make this better and more smooth
//TODO Change background, since it's copied
onMount(() => {
    window.addEventListener("mousemove", setBgPos);
});
function setBgPos(e) {
    let x = e.clientX;
    let y = e.clientY;
    let width = window.innerWidth;
    let height = window.innerHeight;
    if (width < 1024)
        return;
    let offsetConstant = 20;
    let xOffset = ((width / 2 - x) / (width / 2)) * offsetConstant;
    let yOffset = ((height / 2 - y) / (height / 2)) * offsetConstant;
    bg.style.backgroundPosition = `calc(50% + ${xOffset}px) calc(50% + ${yOffset}px)`;
}
</script>

<div
    class="flex overflow-hidden relative flex-col justify-center h-screen bg-gradient-to-b from-green-500 to-blue-500 font-sans">
    <div
        class="hidden absolute z-0 w-full h-full bg-no-repeat bg-cover transition-all duration-75 bg lg:block"
        bind:this="{bg}">
    </div>
    <div class="flex z-10 justify-center h-full lg:h-4/5 height-small">
        <!--ml-{} should be 2*mx-{}+w-{} of PageLayout-->
        <div
            class="flex relative w-full max-w-screen-xl bg-gray-700 lg:w-7xl 3xl:w-4/5 lg:ml-6 xl:ml-28 lg:rounded-3xl">
            <Sidebar bind:active pageIcons="{pageIcons}" />
            <div class="overflow-hidden w-full bg-gray-800 lg:rounded-3xl">
                <slot />
            </div>
        </div>
        <div
            class="hidden absolute right-2 bottom-2 flex-col justify-between w-12 lg:w-16 lg:mx-6 lg:right-0 lg:bottom-0 lg:relative lg:flex">
            <Navbar pageIcons="{pageIcons}" />
            <LRBar pageIcons="{pageIcons}" />
        </div>
    </div>
    <div
        class="block absolute top-2 right-2 z-50 w-12 h-12 bg-green-500 rounded-full cursor-pointer lg:hidden"
        on:click="{() => (active = !active)}">
        <div class="hamburger hamburger--elastic" class:active>
            <div class="hamburger-box">
                <div class="hamburger-inner"></div>
            </div>
        </div>
    </div>
</div>

<!--TODO optimize burger button-->
<style type="scss">.hamburger {
  transform: scale(0.8);
  padding: 12px 4px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: #FFF;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.active:hover {
  opacity: 0.7;
}

.hamburger.active .hamburger-inner {
  background-color: #FFF;
}

.hamburger.active .hamburger-inner::before {
  background-color: #FFF;
}

.hamburger.active .hamburger-inner::after {
  background-color: #FFF;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 40px;
  height: 3px;
  background-color: #FFF;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before {
  width: 40px;
  height: 3px;
  background-color: #FFF;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  content: "";
  display: block;
  top: -10px;
}

.hamburger-inner::after {
  width: 40px;
  height: 3px;
  background-color: #FFF;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  content: "";
  display: block;
  bottom: -10px;
}

.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic.active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic.active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

.bg {
  background-image: url("main_bg.png");
  background-position: calc(50% + 0px) calc(50% + 0px);
  will-change: backgroundPosition;
}

@media (max-height: 640px) {
  .height-small {
    height: 100%;
  }
}</style>
