<script>
    import Calendar from "../../assets/icons/lnr-calendar-full.svg";
    import Pin from "../../assets/icons/lnr-map-marker.svg";
    import Building from "../../assets/icons/lnr-apartment.svg";

    export let title;
    export let institute;
    export let location;
    export let timeframe;
    export let description;
</script>

<div class="p-6 text-white bg-gray-700 rounded-md">
    <h4 class="text-xl">{title}</h4>
    <div class="shadow-inner mb-4 bg-gray-800 mt-2 text-xs flex flex-wrap rounded-sm spacing">
        <div class="py-0.5 px-2.5 inline-block rounded-sm flex-1 min-w-max flex items-center">
            <Building class="inline-block mr-1 w-4 h-4 fill-current" />
            {institute}
        </div>
        <div class="py-0.5 px-2.5 inline-block rounded-sm flex-1 min-w-max flex items-center">
            <Pin class="inline-block mr-1 w-4 h-4 fill-current" />
            {location}
        </div>
        <div class="py-0.5 px-2.5 inline-block rounded-sm flex-1 min-w-max flex items-center">
            <Calendar class="inline-block mr-1 w-4 h-4 fill-current" />
            {timeframe}
        </div>
    </div>
    <p class="font-light">{description}</p>
</div>

<style>.spacing{
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}</style>