<script>
    import { metatags, url } from "@roxi/routify";
    import { MetatagCreator } from "../scripts/MetatagCreator";
    import EduCard from "../components/EduCard.svelte";
    import educations from "../data/edu.json";

    MetatagCreator.createMeta(metatags, $url());
</script>

<div class="overflow-y-auto relative w-full h-full">
    <h3 class="pt-16 pl-16"><span>Education</span></h3>
    <div class="flex pb-5 w-full max-w-3xl">
        <div class="ruler"></div>
        <div class="flex flex-col pr-5 pb-0 mt-6 ml-24 space-y-6 w-full">
            {#each educations as edu}
                <EduCard {...edu} />
            {/each}
        </div>
    </div>
</div>

<style type="scss">.ruler {
  min-width: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  position: relative;
  left: 3rem;
  width: 0.25rem;
}

.ruler:before {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  left: -0.625rem;
  top: -1px;
  position: absolute;
  border-width: 0 0.25rem 0.25rem 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}</style>
