<script lang="ts">import { onDestroy, onMount } from "svelte";
import { metatags, url } from "@roxi/routify";
import { MetatagCreator } from "../scripts/MetatagCreator";
import descriptions from "../data/indexDescriptions.json";
import Rings from "../../assets/favicon.svg";
MetatagCreator.createMeta(metatags, $url());
let currentDescription = 0;
let descriptionNodes = [];
let descriptionInterval;
onMount(() => {
    currentDescription = descriptions.length - 1; //To start with the first one
    cycleDescriptions();
    descriptionInterval = setInterval(cycleDescriptions, 5000);
});
onDestroy(() => clearInterval(descriptionInterval));
function cycleDescriptions() {
    descriptionNodes[currentDescription].classList.add("inactive");
    currentDescription++;
    if (currentDescription >= descriptions.length)
        currentDescription = 0;
    descriptionNodes[currentDescription].classList.remove("inactive");
}
</script>

<div class="flex flex-col justify-center items-center mx-auto w-max h-full">
    <Rings
        class="self-center mr-8 mb-4 w-48 h-52 text-green-500 stroke-current lg:self-end"
        style="stroke-width: 5;" />
    <span
        class="text-4xl text-center text-gray-300 whitespace-nowrap font-comfortaa md:text-5xl lg:text-5xl xl:text-6xl 2xl:text-7xl"
        >Oliver Liebmann</span>
    <p class="relative m-2 mb-40 w-full text-center">
        {#each descriptions as description, i}
            <span
                class="block absolute w-full text-3xl font-light tracking-wider text-center text-gray-300 transition-all duration-700 delay-150 inactive"
                bind:this="{descriptionNodes[i]}"
                >{description}
            </span>
        {/each}
    </p>
</div>

<style type="scss">.inactive {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transition-delay: 0ms;
}</style>
