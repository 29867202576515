<script type="typescript">import { onMount } from "svelte";
export let text;
export let cats;
export let activeCat;
let spinner;
let blink;
onMount(() => {
    spinner.style.animationDelay = Math.floor(Math.random() * -30000) + "ms";
    blink.style.animationDelay = Math.floor(Math.random() * -12000) + "ms";
});
function includes(a1, a2) {
    return a2.some((ele) => a1.includes(ele));
}
function isActive(activeCat, cat) {
    return includes(cats, activeCat) && activeCat.includes(cat) && activeCat.length === 1;
}
</script>

<!-- TODO
text mt-2 should be random
Wenn aktiviert, sollten sie nach einander random größer werden (transition delay)
-->

<div class="w-min">
    <div class="flex m-1 w-min">
        <div class="m-2 w-4 h-4">
            <div bind:this="{spinner}" class="m-auto w-min animate-spin-slow h-min">
                <div
                    bind:this="{blink}"
                    class="block text-gray-500 bg-gray-500 rounded-sm transition-all duration-400"
                    class:skill-active="{includes(cats, activeCat)}"
                    class:skill-inactive="{!includes(cats, activeCat)}"
                    class:animate-blink="{!includes(cats, activeCat)}"
                    class:bg-red-500="{isActive(activeCat, 'Front-End')}"
                    class:bg-purple-500="{isActive(activeCat, 'Machine Learning')}"
                    class:bg-yellow-500="{isActive(activeCat, 'Sys Admin')}"
                    class:bg-pink-500="{isActive(activeCat, 'Languages')}"
                    class:bg-green-500="{isActive(activeCat, 'Back-End')}">
                </div>
            </div>
        </div>
        <span
            class="mt-2 ml-1 text-white transition-all duration-150"
            class:opacity-0="{!includes(cats, activeCat)}">{text}</span>
    </div>
</div>

<style>.skill-active{
  height: 1rem;
  width: 1rem;
}

.skill-inactive{
  height: 0.375rem;
  width: 0.375rem;
}</style>