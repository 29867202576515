<script lang="ts">import { metatags, url } from "@roxi/routify";
import { MetatagCreator } from "../scripts/MetatagCreator";
import MapMarker from "../../assets/icons/lnr-map-marker.svg";
import CheckMark from "../../assets/icons/lnr-checkmark-circle.svg";
import GraduationHat from "../../assets/icons/lnr-graduation-hat.svg";
import Check from "../../assets/icons/lnr-checkmark.svg";
import Cross from "../../assets/icons/lnr-cross.svg";
MetatagCreator.createMeta(metatags, $url());
let sent = false;
let sending = false;
let failure = false;
let form;
let fullName;
let email;
let subject;
let message;
function submit() {
    sending = true;
    sent = false;
    failure = false;
    fetch("https://liebmann.xyz/.netlify/functions/mail", {
        method: "POST",
        body: JSON.stringify({
            "name": fullName,
            "email": email,
            "subject": subject,
            "message": message,
        }),
    }).then(response => {
        if (response.status === 200) {
            form.reset();
            failure = false;
            sent = true;
        }
        else if (response.status === 500) {
            failure = true;
            sent = true;
        }
    }).catch(() => {
        sent = true;
        failure = true;
    }).finally(() => {
        sending = false;
        setTimeout(() => {
            sent = false;
            failure = false;
        }, 5000);
    });
}
</script>

<div class="overflow-y-auto p-16 pb-0 w-full h-full">
    <h3>Contact <span>Me</span></h3>
    <div class="flex flex-col my-8 md:flex-row">
        <div class="w-full md:w-4/12 flex flex-col md:mb-14 md:mt-1.5">
            {#each [[MapMarker, "Vienna, AT"], [GraduationHat, "Student"], [CheckMark, "Available for Work"]] as [Icon, text]}
                <div
                    class="flex-1 flex flex-col justify-center items-center bg-gray-700 w-full min-w-max h-32 border border-gray-600 my-1.5 md:my-4 rounded-md p-6 md:p-1">
                    <Icon class="w-10 h-10 text-green-500 fill-current" />
                    <span class="mt-2 font-bold text-gray-200">{text}</span>
                </div>
            {/each}
        </div>
        <form class="w-full" bind:this="{form}" on:submit|preventDefault="{submit}">
            <div class="hidden m-4 text-4xl font-medium text-gray-300 md:block">
                How Can I <span class="text-green-500">Help</span>
                <span class="text-green-500 dot-shadow">You?</span>
            </div>
            <div class="w-full">
                <div class="relative mt-4 h-10 input">
                    <input id="fullName" type="text" bind:value="{fullName}" required />
                    <label for="fullName">Full Name</label>
                    <div class="input-border"></div>
                </div>
                <div class="relative mt-4 h-10 input">
                    <input id="email" type="email" bind:value="{email}" required />
                    <label for="email">Email Address</label>
                    <div class="input-border"></div>
                </div>
                <div class="relative mt-4 h-10 input">
                    <input id="subject" type="text" bind:value="{subject}" required />
                    <label for="subject">Subject</label>
                    <div class="input-border"></div>
                </div>
                <div class="relative mt-4 h-48 input">
                    <textarea id="message" bind:value="{message}" required></textarea>
                    <label for="message">Message</label>
                    <div class="input-border"></div>
                </div>
                <div class="flex items-center md:ml-4">
                    <div class="flex justify-center w-36 h-10">
                        <button class="submit" class:sent class:sending class:failure>
                            {#if !sending}
                                {#if sent}
                                    {#if failure}
                                        <Cross class="w-6 h-6 text-white fill-current" />
                                    {:else}
                                        <Check class="w-6 h-6 text-white fill-current" />
                                    {/if}
                                {:else}
                                    Send
                                {/if}
                            {/if}
                        </button>
                    </div>
                    {#if sent}
                        {#if failure}
                            <p
                                class="flex-auto px-4 w-full text-base font-normal text-white text-red-500">
                                Something went wrong. Sorry for the inconvenience! Please try again later.
                            </p>
                        {:else}
                            <p
                                class="flex-auto px-4 w-full text-base font-normal text-white text-green-500">
                                Thank you for sending a message. I'll get back to you as soon as
                                possible.
                            </p>
                        {/if}
                    {/if}
                </div>
            </div>
        </form>
    </div>
</div>

<style type="scss">.input {
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;
}

@media (min-width: 768px) {
  .input {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.input input, .input textarea {
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  border-radius: 0.375rem;
  border-width: 2px;
  height: 100%;
}

.input input:focus, .input textarea:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.input input, .input textarea {
  padding: 0.5rem;
  resize: none;
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  width: 100%;
}

.input input:focus ~ label, .input input:valid ~ label, .input input[value] ~ label, .input textarea:focus ~ label, .input textarea:valid ~ label, .input textarea[value] ~ label {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  left: 0.5rem;
  top: -1.25rem;
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.input input:focus ~ .input-border, .input textarea:focus ~ .input-border {
  --tw-scale-x: 1;
}

.input .input-border {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  border-radius: 0.375rem;
  border-width: 2px;
  display: block;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform-origin: left;
  --tw-scale-x: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
}

.input label {
  font-weight: 300;
  pointer-events: none;
  position: absolute;
  top: 0.5rem;
  left: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.submit {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  font-weight: 300;
  height: 100%;
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  width: 100%;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.submit.sending {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
  width: 2.5rem;
  animation: spin 1s linear infinite;
  border-bottom-color: currentColor;
  animation-delay: 150ms;
}

.submit:hover:not(.sending) {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.submit.sent:not(.sending) {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}

.submit.failure:not(.sending) {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}</style>
