<script>
    import { url } from "@roxi/routify";
    import Hexagon from "../../assets/hexagon.svg";

    export let active;
    export let pageIcons;
</script>

<div
    class="flex overflow-y-auto absolute right-0 z-50 flex-col items-center w-80 h-full bg-gray-700 border-l-2 border-gray-300 transition-all duration-300 transform translate-x-full lg:w-96 lg:h-auto lg:my-16 lg:mx-10 lg:right-auto lg:relative lg:translate-x-0 lg:border-l-0"
    class:active>
    <Hexagon
        class="pt-16 mb-2 w-20 h-auto text-green-500 stroke-current stroke-2 fill-transparent sm:w-40 lg:pt-0" />
    <h1 class="py-4 pb-2 text-5xl text-center text-gray-300">Oliver Liebmann</h1>
    <h2 class="font-light text-gray-300 text-md">AI and Phamarcy Student</h2>
    <ul class="flex flex-col items-center p-8 pb-2 space-y-3 lg:hidden">
        {#each pageIcons as [Icon, label, link]}
            <li>
                <a
                    href="{$url(link)}"
                    class="relative p-2 w-14 h-14 text-gray-300 cursor-pointer group hover:text-blue-500"
                    on:click="{() => (active = false)}">{label}</a>
            </li>
        {/each}
    </ul>
    <ul class="flex justify-around p-6 w-3/5 text-gray-400">
        <li><a> A </a></li>
        <li><a> B </a></li>
        <li><a> C </a></li>
        <li><a> D </a></li>
    </ul>
    <a
        href="/contact"
        class="py-2 px-8 m-2 text-gray-300 rounded-full border-2 transition-colors hover:bg-gray-300 hover:text-blue-600 focus:outline-none"
        >Contact</a>
    <a
        href="Resume_Oliver_liebmann.pdf"
        target="_blank"
        class="py-2 px-8 m-2 text-gray-300 rounded-full border-2 transition-colors cursor-pointer disabled:opacity-60 hover:bg-gray-300 hover:text-blue-600 focus:outline-none">
        Download CV
    </a>
</div>

<style>.active {
  transform: translateX(0px);
}</style>
