<script>
    import { isActive, url } from "@roxi/routify";

    export let pageIcons;
</script>

<div class="hidden flex-col justify-around items-center p-4 bg-gray-700 rounded-full lg:flex">
    {#each pageIcons as [Icon, label, link]}
        <a
            href="{$url(link)}"
            aria-label="Route to {label}"
            class="relative p-2 w-14 h-14 text-gray-300 cursor-pointer group"
            class:active="{$isActive(link, false)}">
            <Icon class="transition duration-300 fill-current group-hover:text-blue-500" />
            <span
                class="w-auto py-0.5 px-3 bg-blue-500 text-gray-300 invisible whitespace-nowrap rounded-l-md shadow-md absolute opacity-0 top-1/2 right-0 -mt-3 mr-1 transition-all duration-300 ease-in-out group-hover:opacity-100 group-hover:right-full group-hover:visible"
                >{label}</span>
        </a>
    {/each}
</div>

<style>.active{
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}</style>